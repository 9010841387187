import React, { FC, memo, useMemo } from 'react';

import { Button, IconButton, Tooltip, useTranslation } from '@just-ai/just-ui';
import cn from 'classnames';

import styles from './style.module.scss';
import { appOptions } from '../../api/cc.api';
import { showBillingModal } from '../../hooks/showBilling';
import svgJToken from '../../images/JToken.svg';
import { isTovie } from '../../isTovie';
import localize from '../../localization';
import { currentUser, hasPermission } from '../../models/currentUser';

const AccountLimit: FC = memo(() => {
  const { t, getLocale } = useTranslation();

  const accountLimit = currentUser.value?.accountLimit;

  const limitsToDisplay = useMemo(() => {
    const displayBalance = { trunc: '0', frac: '0' };
    if (accountLimit) {
      const balance = accountLimit.remainingTokenLimit || 0;
      displayBalance.trunc = Math.trunc(balance / 1000).toLocaleString(getLocale(), { maximumFractionDigits: 3 });
      displayBalance.frac = (balance % 1000).toPrecision();
    }
    return displayBalance;
  }, [accountLimit, getLocale]);

  const limitRefreshPeriod = useMemo(
    () =>
      accountLimit?.refreshPeriod &&
      new Date(accountLimit?.nextRefresh as unknown as number).toLocaleString(getLocale(), {
        month: 'long',
        day: 'numeric',
      }),
    [getLocale]
  );

  return (
    <div className={styles.accountPage__block}>
      <div className='flex justify-between'>
        <p className={styles.accountPage__block__text}>{t('tokenLimit')}</p>
        <IconButton
          id='balanceTooltip'
          name='farQuestionCircle'
          aria-label={t('tokenTooltip')}
          className={cn(styles.accountPage__block__tooltip, 'gray-600')}
        />
        <Tooltip
          placement='bottom'
          target='balanceTooltip'
          autohide={false}
          innerClassName={cn(styles.accountPage__block__tooltipInner)}
        >
          <p>{t('tokenTooltip')}</p>
          {!isTovie && (
            <p className='mt-3'>
              <a
                href={`${t('docsUrl')}/pricing/`}
                target='_blank'
                rel='noreferrer'
                className={styles.accountPage__block__tooltipInner__link}
              >
                {t('tokenTooltip:more')}
              </a>
            </p>
          )}
        </Tooltip>
      </div>
      <p className={styles.accountPage__block__info}>
        <img src={svgJToken} alt='J-token logo' />
        <div>
          <span data-test-id='Account.tokenAmount'>{limitsToDisplay.trunc}</span>
          <span className={styles.accountPage__block__info__frac}>.{limitsToDisplay.frac}</span>
        </div>
      </p>
      {accountLimit?.tokenLimit && (
        <>
          <p className='text-secondary'>
            {t('Account:limit:amount', {
              amount: (accountLimit?.tokenLimit / 1000).toLocaleString(getLocale(), {
                maximumFractionDigits: 3,
              }),
              tokenString: localize.translateWithPlural('jayToken', accountLimit?.tokenLimit),
            })}
          </p>
          <p className='text-secondary mb-2'>
            {t('Account:limit:refresh', {
              date: limitRefreshPeriod,
            })}
          </p>
          {/* TODO return after article created {
            <a className='mb-2' href={t('Account:limit:moreLink')} target='_blank' rel='noopener noreferrer'>
              {t('LimitsMore')}
            </a>
          } */}
        </>
      )}
      {hasPermission('COPILOT_BUY_PACKAGE') && (
        <Button data-test-id='Account.payBtn' color='primary' className='w-full' onClick={() => showBillingModal(true)}>
          {t('payBtnCaption')}
        </Button>
      )}
    </div>
  );
});

export default AccountLimit;
