import React, { FC, memo, useMemo } from 'react';

import { Button, IconButton, Tooltip, useTranslation } from '@just-ai/just-ui';
import cn from 'classnames';

import styles from './style.module.scss';
import { showBillingModal } from '../../hooks/showBilling';
import svgJToken from '../../images/JToken.svg';
import { isTovie } from '../../isTovie';
import { hasPermission } from '../../models/currentUser';

interface AccountBalanceProps {
  balance?: number;
}

const AccountBalance: FC<AccountBalanceProps> = memo(({ balance }) => {
  const { t, getLocale } = useTranslation();

  const balanceToDisplay = useMemo(() => {
    const displayBalance = { trunc: '0', frac: '0' };
    if (balance) {
      displayBalance.trunc = Math.trunc(balance / 1000).toLocaleString(getLocale(), { maximumFractionDigits: 3 });
      displayBalance.frac = (balance % 1000).toPrecision();
    }
    return displayBalance;
  }, [balance, getLocale]);

  return (
    <div className={styles.accountPage__block}>
      <div className='flex justify-between'>
        <p className={styles.accountPage__block__text}>{t('tokenBalance')}</p>
        <IconButton
          id='balanceTooltip'
          name='farQuestionCircle'
          aria-label={t('tokenTooltip')}
          className={cn(styles.accountPage__block__tooltip, 'gray-600')}
        />
        <Tooltip
          placement='bottom'
          target='balanceTooltip'
          autohide={false}
          innerClassName={cn(styles.accountPage__block__tooltipInner)}
        >
          <p>{t('tokenTooltip')}</p>
          {!isTovie && (
            <p className='mt-3'>
              <a
                href={`${t('docsUrl')}/pricing/`}
                target='_blank'
                rel='noreferrer'
                className={styles.accountPage__block__tooltipInner__link}
              >
                {t('tokenTooltip:more')}
              </a>
            </p>
          )}
        </Tooltip>
      </div>
      <p className={styles.accountPage__block__info}>
        <img src={svgJToken} alt='J-token logo' />
        <div>
          <span data-test-id='Account.tokenAmount'>{balanceToDisplay.trunc}</span>
          <span className={styles.accountPage__block__info__frac}>
            {getLocale() === 'ru' ? ',' : '.'}
            {balanceToDisplay.frac}
          </span>
        </div>
      </p>
      {hasPermission('COPILOT_BUY_PACKAGE') && (
        <Button data-test-id='Account.payBtn' color='primary' className='w-full' onClick={() => showBillingModal(true)}>
          {t('payBtnCaption')}
        </Button>
      )}
    </div>
  );
});

export default AccountBalance;
