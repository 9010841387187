import { useCallback, useMemo } from 'react';

import {
  ConversationAndAppCreateRequest,
  ConversationCreateRequest,
  TemplateResponse,
} from '@just-ai/api/dist/generated/AppsAdapter';
import { RequestAccessRequest } from '@just-ai/api/dist/generated/CopilotGateway';
import GatewayService from '@just-ai/api/dist/services/CopilotGatewayService';
import { format } from 'date-fns';
import { DateTime } from 'luxon';
import { updateAgent } from 'models/agents';
import { sendMessageToChat } from 'models/conversations';
import { deleteConversation, updateUserChat } from 'models/conversations/apiHooks';

import { appsAdapterService } from './service';
import axios from '../api/client';
import BillingService from '../api/services/BillingService';
import { templates } from '../models/templates';
import { DownloadReportRequestType, ExpensesRequestType, ReplenishmentsRequestType } from '../types/reports';
import { AppLogger } from '@just-ai/logger';

const sortTemplates = (templates: { [key: string]: TemplateResponse }, locale: string) => {
  const templateEntries = Object.entries(templates);
  templateEntries.sort(([, a], [, b]) => a.info.title.localeCompare(b.info.title, locale));
  return Object.fromEntries(templateEntries);
};

const templatesMapToList = (templates: { [key: string]: TemplateResponse }) => {
  return Object.keys(templates).map(name => ({ ...templates[name], template: name }));
};

const useApiService = () => {
  const appsAdapterApi = appsAdapterService;
  const billingApi = useMemo(() => new BillingService(axios), []);
  const gatewayApi = useMemo(() => new GatewayService(axios), []);

  const getTemplatesForLocale = useCallback(
    async (locale: string) => {
      const responseTemplates = await appsAdapterApi.getTemplatesForLocale();

      const { templates } = responseTemplates.data;

      const sortedTemplates = sortTemplates(templates, locale);

      return {
        templatesMap: sortedTemplates,
        templatesList: templatesMapToList(sortedTemplates),
      };
    },
    [appsAdapterApi]
  );

  const getTelegramTemplatesForLocale = useCallback(
    async (locale: string) => {
      const {
        data: { templates },
      } = await appsAdapterApi.getTelegramTemplatesForLocale(locale);

      const sortedTemplates = sortTemplates(templates, locale);

      return {
        templatesMap: sortedTemplates,
        templatesList: templatesMapToList(sortedTemplates),
      };
    },
    [appsAdapterApi]
  );

  const uploadFile = useCallback(
    async (data: any, abortController?: AbortController, options?: any) => {
      return await appsAdapterApi.uploadFile(data, abortController, options);
    },
    [appsAdapterApi]
  );

  const getBalance = useCallback(async () => {
    return await billingApi.getBalance();
  }, [billingApi]);

  const buyPackage = useCallback(
    async (name: string) => {
      return await billingApi.buyPackage(name);
    },
    [billingApi]
  );

  const sendUserActionToAnalytics = useCallback(
    (eventData: { eventName: string; eventValue?: any }) => {
      gatewayApi.sendEvent(eventData).catch(error => {
        AppLogger.error({
          message: `Failed to send action to analytics ${eventData.eventName}, ${eventData.eventValue}`,
          exception: error,
        });
      });
    },
    [gatewayApi]
  );

  const getMainChat = useCallback(async () => {
    return appsAdapterApi.getMainChat();
  }, [appsAdapterApi]);

  const createUserChat = useCallback(
    async (
      config: ConversationCreateRequest | ConversationAndAppCreateRequest,
      async: boolean = false,
      abortController?: AbortController
    ) => {
      if (!config.name && 'template' in config.app) {
        const templateName = templates.value.templatesMap?.[config.app.template]?.info.title;
        config.name = templateName ? `${templateName} - ${format(new Date(), 'HH:mm dd.MM')}` : undefined;
      }
      return appsAdapterApi.createUserConversation(config, async, abortController);
    },
    [appsAdapterApi]
  );

  const createUserAgent = useCallback(
    async (config, abortController?: AbortController) => {
      return appsAdapterApi.createUserAgent(config, abortController);
    },
    [appsAdapterApi]
  );

  const prevalidateApp = useCallback(
    async (config, abortController?: AbortController) => {
      return appsAdapterApi.prevalidateApp(config, abortController);
    },
    [appsAdapterApi]
  );

  const getUserChats = useCallback(
    async (
      params?: {
        hasUnreadChanges?: boolean;
        orderBy?: 'created' | 'updated';
        sort?: 'asc' | 'desc';
      },
      abortController?: AbortController
    ) => {
      return appsAdapterApi.getUserChats(params, abortController);
    },
    [appsAdapterApi]
  );

  const getUserChat = useCallback(
    async (chatId: string, abortController?: AbortController) => {
      return appsAdapterApi.getUserChat(chatId, abortController);
    },
    [appsAdapterApi]
  );

  const findFavoriteApps = useCallback(async () => {
    return appsAdapterApi.findFavoriteApps();
  }, [appsAdapterApi]);

  const sendUserRequestForFeature = useCallback(
    async (featureData: RequestAccessRequest) => {
      gatewayApi.requestFeature(featureData);
    },
    [gatewayApi]
  );

  const getChatHistory = useCallback(
    async (chatId: string, oldestMessageTimestamp: number) => {
      return appsAdapterApi.getChatHistory(chatId, oldestMessageTimestamp);
    },
    [appsAdapterApi]
  );

  const clearChatHistory = useCallback(
    async (chatId: string) => {
      return appsAdapterApi.clearChatHistory(chatId);
    },
    [appsAdapterApi]
  );

  const cancelMessageProcessing = useCallback(
    async (chatId: string) => {
      return appsAdapterApi.cancelMessageProcessing(chatId);
    },
    [appsAdapterApi]
  );

  const getUserApiKeys = useCallback(
    async (userId: number) => {
      return gatewayApi.getApiKeys(userId);
    },
    [gatewayApi]
  );

  const createUserApiKey = useCallback(
    async (userId: number) => {
      return gatewayApi.createApikey(userId);
    },
    [gatewayApi]
  );

  const getJGuardKeys = useCallback(async () => {
    return gatewayApi.getAllDataGuardKeys();
  }, [gatewayApi]);

  const setJGuardKey = useCallback(
    async (key: string, sendEmail?: boolean) => {
      return gatewayApi.updateApiToken(key, true, sendEmail);
    },
    [gatewayApi]
  );

  const disableJGuardKey = useCallback(
    async (key?: string) => {
      return gatewayApi.updateApiToken(key, false);
    },
    [gatewayApi]
  );

  const getCurrentJGuardKey = useCallback(async () => {
    return gatewayApi.getCurrentDataGuardKey();
  }, [gatewayApi]);

  const validateCurrentJGuardKey = useCallback(async () => {
    return gatewayApi.validateJGuardKey();
  }, [gatewayApi]);

  type ExpensesRequestData = {
    periodFrom: Date;
    periodTo: Date;
    groupBy: ExpensesRequestType['groupBy'];
    locale: 'eng' | 'ru';
    page?: ExpensesRequestType['page'];
    sort?: ExpensesRequestType['sort'];
    userId?: number;
    search?: ExpensesRequestType['search'];
    size?: number;
  };

  const getExpensesHistory = useCallback(
    async (requestData: ExpensesRequestData) => {
      const formattedRequest: ExpensesRequestType = {
        ...requestData,
        periodFrom: DateTime.fromJSDate(requestData.periodFrom).startOf('day').toJSDate(),
        userTimeZone: DateTime.now().zoneName,
        periodTo: DateTime.fromJSDate(requestData.periodTo).endOf('day').toJSDate(),
        sort: requestData.sort || ['date', 'desc'],
        lang: requestData.locale === 'ru' ? requestData.locale : 'en',
      };

      const rawData = await gatewayApi.getExpensesHistory(formattedRequest);
      return {
        ...rawData,
        data: {
          ...rawData.data,
          expenses: rawData.data.expenses.map(expense => ({ ...expense, tokenAmount: expense.tokenAmount / 1000 })),
        },
      };
    },
    [gatewayApi]
  );

  const getReplenishmentHistory = useCallback(
    async (requestData: ReplenishmentsRequestType) => {
      const formattedRequest: ReplenishmentsRequestType = requestData;

      const rawData = await gatewayApi.getReplenishmentHistory(formattedRequest);
      return {
        ...rawData,
        data: {
          ...rawData.data,
          replenishments: rawData.data.replenishments.map(replenishment => ({
            ...replenishment,
            tokenAmount: replenishment.tokenAmount / 1000,
          })),
        },
      };
    },
    [gatewayApi]
  );

  const getExpensesReportUrl = useCallback(
    async (requestData: DownloadReportRequestType) => {
      return gatewayApi.createDownloadReportUrl({
        ...requestData,
        lang: requestData.locale === 'ru' ? requestData.locale : 'en',
        timezone: DateTime.now().zoneName,
      });
    },
    [gatewayApi]
  );

  const getUserLimits = useCallback(
    async (accountId: number, userId?: number) => {
      return gatewayApi.getUserLimit({ accountId, userId });
    },
    [gatewayApi]
  );

  const getCurrentUserLimits = useCallback(async () => {
    return gatewayApi.getCurrentUserLimits();
  }, [gatewayApi]);

  return {
    getTemplatesForLocale,
    getTelegramTemplatesForLocale,
    findFavoriteApps,
    getUserChats,
    getUserChat,
    createUserAgent,
    prevalidateApp,
    sendMessageToChat,
    deleteConversation,
    updateAgent,
    updateUserChat,
    uploadFile,
    getBalance,
    buyPackage,
    sendUserActionToAnalytics,
    sendUserRequestForFeature,
    createUserChat,
    getMainChat,
    getChatHistory,
    clearChatHistory,
    cancelMessageProcessing,
    getUserApiKeys,
    createUserApiKey,
    getJGuardKeys,
    setJGuardKey,
    disableJGuardKey,
    getCurrentJGuardKey,
    validateCurrentJGuardKey,
    getExpensesHistory,
    getReplenishmentHistory,
    getExpensesReportUrl,
    getUserLimits,
    getCurrentUserLimits,
  };
};

export default useApiService;
