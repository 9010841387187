import React, { FC, memo, useEffect } from 'react';

import { AlertNotification } from '@just-ai/just-ui';
import { AppLogger } from '@just-ai/logger';
import cn from 'classnames';
import {
  paymentData,
  recursivePaymentProcessingPolling,
  setPaymentData,
  showBilling,
  showBillingModal,
} from 'hooks/showBilling';

import styles from './style.module.scss';
import { appOptions } from '../../api/cc.api';
import { isDemoMode } from '../../api/client';
import BillingModal from '../../components/BillingModal';
import { Chatbar } from '../../components/Chatbar/Chatbar';
import { HuggingfaceBillingInformer } from '../../components/HuggingfaceBillingInformer/HuggingfaceBillingInformer';
import SignupModal from '../../components/SignupModal';
import BillingProcessingPanel from '../../components/TopPanel/BillingProcessingPanel';
import DemoModePanel from '../../components/TopPanel/DemoModePanel';
import LimitsPanel from '../../components/TopPanel/LimitsPanel';
import AppContext, { AppInitialState, initialState } from '../../contexts/appContext';
import { useCreateReducer } from '../../hooks/useCreateReducer';
import localize from '../../localization';
import { addAlert, alerts } from '../../models/alerts';
import {
  handleDeleteConversation,
  handleNewConversation,
  handleUpdateConversation,
  handleDeleteMessage,
} from '../../models/conversations';
import { jGuardFailedToProtect, setJGuardFailureToProtect } from '../../models/conversations/signals';
import { currentUser, pollCurrentUserForPaymentRequests, setCurrentUser } from '../../models/currentUser';
import useApiService from '../../services/useApiService';
import { isMobile, setAppFavicon } from '../../utils/app/common';
import { getSettings } from '../../utils/app/settings';

const limitsWarningThreshold = 0.2;

export const FullContainer: FC = memo(({ children }) => {
  const contextValue = useCreateReducer<AppInitialState>({
    initialState,
  });

  const {
    state: { lightMode, locale, showChatbar },
    dispatch,
  } = contextValue;

  const accountLimit = currentUser.value?.accountLimit;

  const { validateCurrentJGuardKey, getCurrentUserLimits } = useApiService();

  // EFFECTS  --------------------------------------------

  useEffect(() => {
    if (window.innerWidth < 640) {
      dispatch({ field: 'showChatbar', value: false });
    }
  }, [dispatch]);

  // ON LOAD --------------------------------------------

  useEffect(() => {
    const settings = getSettings();
    if (settings.theme) {
      dispatch({
        field: 'lightMode',
        value: settings.theme,
      });
    }

    dispatch({ field: 'locale', value: localize.getLocale() });

    pollCurrentUserForPaymentRequests().then(result => {
      if (result.pendingRequests) {
        setPaymentData({
          status: result.pendingRequests > 1 ? 'pending_many' : 'pending_one',
          pendingAmount: result.pendingRequests,
        });
        recursivePaymentProcessingPolling();
      }
    });

    validateCurrentJGuardKey().then(result => {
      setJGuardFailureToProtect(!result.data);
    });

    getCurrentUserLimits()
      .then(response => {
        const { data: limitsRes } = response;
        setCurrentUser({ ...currentUser.value, accountLimit: limitsRes?.limit });
      })
      .catch(error => {
        AppLogger.error({ message: 'error gettinf user limits', exception: error });
      });

    if (window.innerWidth < 640) {
      dispatch({ field: 'showChatbar', value: false });
    }

    //change favicon if its present in options
    setAppFavicon(appOptions?.value?.product?.favicon);
  }, [dispatch, getCurrentUserLimits, validateCurrentJGuardKey]);

  const showLimitsPanel =
    accountLimit &&
    accountLimit.remainingTokenLimit !== undefined &&
    accountLimit.tokenLimit !== undefined &&
    accountLimit.remainingTokenLimit <= accountLimit.tokenLimit * limitsWarningThreshold;

  return (
    <AppContext.Provider
      value={{
        ...contextValue,
        handleNewConversation,
        handleUpdateConversation,
        handleDeleteConversation,
        handleDeleteMessage,
        addAlert,
      }}
    >
      <main
        className={cn(`${lightMode}`, styles.homePage__mainContainer, {
          [styles.isSidebarOpen]: showChatbar,
          [styles.homePage__mainContainer_offset]:
            !isMobile() && (isDemoMode.value || jGuardFailedToProtect.value || paymentData.value || showLimitsPanel),
        })}
      >
        <HuggingfaceBillingInformer />
        <Chatbar />
        <div className={cn(styles.homePage__chatContainer)}>
          {showLimitsPanel && <LimitsPanel />}
          <BillingProcessingPanel />
          <DemoModePanel />

          {children}
        </div>
        <AlertNotification
          alerts={alerts.value}
          locale={locale === 'eng' ? 'en' : 'ru'}
          //@ts-ignore
          dismissAlert={() => {}}
          dismissAllAlerts={() => (alerts.value = [])}
        />
        <BillingModal
          type={showBilling.value === 'outOfTokens' ? 'outOfTokens' : 'payment'}
          isShown={!!showBilling.value}
          close={() => showBillingModal(false)}
        />
        <SignupModal />
      </main>
    </AppContext.Provider>
  );
});
FullContainer.displayName = 'memo(FullContainer)';
